/* 
Fonts:

fonts {
    font-family: 'Hind Madurai', sans-serif;
    font-family: 'Lato', sans-serif;
    font-family: 'Lora', serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Outfit', sans-serif;
    font-family: 'Raleway', sans-serif;

    font-family: 'Anton', sans-serif;
        font-family: 'Babylonica', cursive;
        font-family: 'Dancing Script', cursive;
        font-family: 'Great Vibes', cursive;
        font-family: 'Josefin Sans', sans-serif;
        font-family: 'Lato', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-family: 'Outfit', sans-serif;
        font-family: 'Passion One', cursive;
        font-family: 'Raleway', sans-serif;
        font-family: 'Sen', sans-serif;
}
Colors:

 colors {
    black: #3a3a3a;
} */

/* new navbar start */
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

.navigation {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: #3a3a3a;
  color: black;
}

.navigation-menu {
  z-index: 1;
  width: 100%;
}

.navigation-menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: blue;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    /* border-top: 1px solid black; */
  }

  .navigation-menu.expanded ul {
    display: block;
    height: auto;
    background-color: #3a3a3a;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: white;
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }
}

/* new navbar end */

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border: 1px solid black;
  width: 60%;
}

ol {
  list-style-type: circle;
  color: #3a3a3a;
}

img {
  pointer-events: none;
}

label {
  margin-left: 2rem;
  font-size: 14px;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

.SimpleCodesPH {
  width: 10rem;
  height: auto;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 100%;
  background-color: #3a3a3a;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  margin: auto;
  width: 100%;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lora", serif;
  width: 30%;
}

.navbar-logo img {
  height: 2.5rem;
}

.navbar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: auto;
}

.navbar-links ul {
  margin: 0;
}

.navbar-links-Link {
  padding: 0 1rem;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
}

.navbar-link-Link.avtive {
  color: blue;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  margin: auto;
}

.carousel-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.carousel {
  position: inherit;
  height: 100vh;
  width: 100%;
}

/* .carousel-button-container {
    position: absolute;
    left: 45vw;
    bottom: 2rem;
    height: 2rem;
    width: 100%;
}

.carousel-button-container i {
    color: white;
    padding: 0 3px;
}

.carousel-button-container i:hover,
.carousel-button-container i:active {
    color: #3a3a3a99;
} */

.carousel-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-info img {
  width: 10rem;
  max-height: 9rem;
  /* padding-top: 2rem; */
}

.carousel-info-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 27rem;
  height: 25rem;
  padding: 3rem 0;
  /* background-color: #ffffff55;
     */
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: 1px 1px 20px 10px #3a3a3a; */
  border-radius: 5px;
}

.for-as-low-as {
  margin-top: 2rem;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
}

.monthly {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
}

.description {
  padding: 0 4.5rem;
  font-family: "Lato", sans-serif;
  text-align: justify;
}

.properties-banner {
  background-image: url(https://images.unsplash.com/photo-1619031933172-805a13431fe4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
}

.page-banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    #3a3a3a 10%,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  height: 100%;
  width: 100%;
}

.page-banner-content h1,
.page-banner-content p {
  color: white;
  text-align: center;
  width: 100%;
}

.page-banner-content div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
}

.properties-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 5rem 0;
}

.properties-div {
  margin-bottom: 2rem;
}

.properties-div h2 {
  background-color: #3a3a3a;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 0.8rem 0rem 0.5rem 1rem;
  font-size: 1.2rem;
  height: 3rem;
  border-radius: 3px 3px 0 0;
  margin: 0;
  font-weight: 400;
}

.properties-div-details {
  padding: 1rem;
  text-align: justify;
  border-radius: 0 0 3px 3px;
  background-color: #3a3a3a11;
  height: auto;
}

.properties-div-details p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #3a3a3a99;
}

.properties-div a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.properties-div button {
  color: white;
  text-decoration: none;
  cursor: pointer;
  background-color: #3a3a3a55;
  border-radius: 3px;
  margin: 0 0 0 1rem;
  border: none;
  outline: none;
  width: 10rem;
  height: 2rem;
  text-align: center;
  padding: 0.2rem;
  font-family: "Lato", sans-serif;
}

.properties-div button:hover {
  background-color: #3a3a3a;
  color: white;
  transition: 300ms;
}

.properties-div-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
}

.properties-button {
  width: 12rem;
  height: 2rem;
  margin: 0;
  font-size: 1.1rem;
  border: none;
  background-color: transparent;
  color: #3a3a3a99;
  cursor: pointer;
}

.preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.preview-card {
  margin-bottom: 4rem;
  width: 20rem;
  border: 1px solid green;
  height: 35rem;
}

.preview-image-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  );
}

.preview-image-info img {
  border: 1px solid red;
  width: 18rem;
  height: auto;
  margin: auto;
}

.button-view-more-properties {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 100%;
  background-color: #3a3a3a77;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 5rem;
}

.button-view-more-properties button {
  color: white;
  background-color: transparent;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: "Lato", sans-serif;
}

.button-view-more-properties:hover {
  background-color: #3a3a3a;
  color: white;
}

.contact-banner {
  background-image: url(https://images.unsplash.com/photo-1517777298614-cb6eefb19fad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
}

.email-container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.contact-and-input-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: auto auto 5rem auto;
}

.contact {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.contact-title {
  width: 100%;
  text-align: left;
  padding-left: 2rem;
  margin-top: 1rem;
}

.contact-div div {
  margin-left: 0;
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  width: 100%;
  padding: 0.6rem;
  color: #3a3a3a88;
  height: 100%;
}

.contact-info p {
  text-align: left;
  font-family: "Lato", sans-serif;
  text-decoration: none;
}

.contact-info > :nth-child(1) {
  padding: 0 2rem 1rem 1.2rem;
  font-size: 1.5rem;
  width: 1rem;
  margin-left: 0;
}

.contact-info > :nth-child(2) {
  width: 6rem;
}

.contact-info > :nth-child(3) {
  width: 11rem;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-floating-icons {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 10rem;
  box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
  -webkit-box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
  -moz-box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
  backdrop-filter: blur(10px) grayscale(100%);
  background-color: rgba(211, 211, 211, 0.178);
}

.contact-floating-icons i {
  font-size: 1.8rem;
  padding: 0.7rem;
  color: white;
  border: 1px solid transparent;
  /* background-color: rgba(82, 82, 82, 0.192); */
}

.email-icon {
  background-color: #db4437;
  color: white;
}

.email-icon:hover {
  color: white;
  background-color: #d67067;
  cursor: pointer;
  transition: 300ms;
}

.messenger-icon {
  background-color: #4267b2;
}

.messenger-icon:hover {
  background-color: #608dc2;
  color: white;
  /* border: 1px solid lightgray; */
  cursor: pointer;
  transition: 300ms;
}

.whatsapp-icon {
  background-color: #128c7e;
  color: white;
  width: 100%;
}

.whatsapp-icon:hover {
  background-color: #2fb6a6;
  color: white;
  cursor: pointer;
  transition: 300ms;
}

.viber-icon {
  background-color: #8f5db7;
  color: white;
}

.viber-icon:hover {
  background-color: #aa7bd1;
  color: white;
  cursor: pointer;
  transition: 300ms;
}

.contact-form-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px) grayscale(50%);
  z-index: 100;
}

.contact-form-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 768px;
  padding: 1rem 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
  -webkit-box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
  -moz-box-shadow: -1px 1px 30px -7px rgba(59, 52, 52, 0.57);
}

/* // modal // */
.modal-button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.input-modal {
  width: 90%;
}

.input-container-modal {
  width: 100%;
  color: #3a3a3a88;
  font-family: "Lato", sans-serif;
}

.input-container-modal i {
  width: 100%;
  text-align: right;
  font-size: 1.5rem;
}

.input-container-modal input {
  width: 100%;
  height: 2.5rem;
  margin: 1rem 0;
  border: 1px solid #3a3a3a88;
  border-radius: 2px;
  padding-left: 1rem;
}

.input-container-modal input::placeholder,
.input-container-modal textarea::placeholder {
  color: #3a3a3a88;
  font-family: "Lato", sans-serif;
}

.input-container-modal textarea {
  margin-top: 1rem;
  width: 100%;
  border: 1px solid #3a3a3a88;
  border-radius: 2px;
  height: 10rem;
  padding-left: 1rem;
  padding-top: 1rem;
  margin-top: 1rem;
  text-align: left;
}

.input-container-modal button {
  background-color: #3a3a3a;
  color: #ffffff;
  height: 3rem;
  width: 10rem;
  border: none;
  border-radius: 2px;
  margin-top: 1rem;
}

.input-container-modal button:hover {
  background-color: lightgray;
  color: #3a3a3a;
  border: 1px solid #3a3a3a;
}
/* // modal // */

.input {
  width: 50%;
}

.input-container {
  width: 100%;
  color: #3a3a3a88;
  font-family: "Lato", sans-serif;
}

.input-container input {
  width: 100%;
  height: 2.5rem;
  margin: 1rem 0;
  border: 1px solid #3a3a3a88;
  border-radius: 2px;
  padding-left: 2rem;
}

.input-container input::placeholder,
.input-container textarea::placeholder {
  color: #3a3a3a88;
  font-family: "Lato", sans-serif;
}

.input-container textarea {
  margin-top: 1rem;
  width: 100%;
  border: 1px solid #3a3a3a88;
  border-radius: 2px;
  height: 10rem;
  padding-left: 2rem;
  padding-top: 1rem;
  margin-top: 1rem;
  text-align: left;
}

.input-container button {
  background-color: #3a3a3a;
  color: #ffffff;
  height: 3rem;
  width: 10rem;
  border: none;
  border-radius: 2px;
  margin-top: 1rem;
}

.input-container button:hover {
  background-color: lightgray;
  color: #3a3a3a;
  border: 1px solid #3a3a3a;
}

.card-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  /* padding-bottom: 5rem; */
  width: 100%;
}

.card {
  width: 20rem;
  height: 47rem;
  margin: 0 1rem;
  border: none;
}

.card-image-container {
  height: 23rem;
}

.card-image-container div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.7) 60%,
    rgba(0, 0, 0, 1) 100%
  );
}

.card-image-container div img {
  width: 50%;
  height: auto;
}

.card-status {
  position: absolute;
  background-color: blue;
  color: white;
  left: 0;
  top: 1rem;
  padding: 0.5rem;
  font-family: "Lato", serif;
}

.card-price {
  position: absolute;
  background-color: blue;
  color: white;
  bottom: 1rem;
  left: 1rem;
}

.card-info {
  font-family: "Lato", serif;
  color: #3a3a3a88;
}

.card-info div {
  height: 3rem;
}

.card-info h2 {
  margin: 0.5rem 0;
  color: #3a3a3a;
  font-size: 1.4rem;
  height: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-info p:nth-child(3) {
  margin: 0;
  height: 12rem;
  border-bottom: 1px solid #3a3a3a99;
}

.card-info p:nth-child(4) {
  margin-top: 0;
  height: 4rem;
}

.card-info i {
  margin-right: 0.5rem;
}

.single-item-banner {
  margin-bottom: 5rem;
}

.single-item-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    #3a3a3a 100%
  );
  height: 100%;
}

.single-item-banner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.single-item-banner img {
  width: 40%;
  max-height: 75%;
}

.single-item-banner-container div {
  background-color: rgba(255, 255, 255, 0.9);
  width: 29%;
  min-height: 30rem;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  word-spacing: 5px;
  border-radius: 5px;
}

.single-item-banner-container h1,
.single-item-banner-container h2 {
  text-align: left;
  color: #3a3a3a99;
  font-size: 2rem;
}

.single-item-banner-container h1 {
  margin: 0rem 0 2rem 2rem;
}

.single-item-banner-container h2 {
  margin: 0 0 2rem 2rem;
}

.single-item-banner-container p,
li {
  color: #3a3a3a99;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.single-item-banner-container ol {
  margin-left: 1rem;
}

.single-item-banner-container li {
  padding-right: 2rem;
  line-height: 1.7rem;
  text-align: left;
}

.single-item-banner-container p {
  text-align: justify;
  word-spacing: 2px;
  line-height: 1.5rem;
  padding: 1rem 2rem 2rem 2rem;
  text-align: left;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.gallery img {
  width: 20rem;
  height: auto;
  margin: 1rem 0.5rem;
}

.search-container {
  background-image: url(https://images.unsplash.com/photo-1515378960530-7c0da6231fb1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  background: rgb(58, 58, 58);
  background: linear-gradient(
    180deg,
    rgba(58, 58, 58, 1) 1%,
    rgba(58, 58, 58, 0.5) 50%,
    rgba(58, 58, 58, 0.5) 100%
  );
}

.search input {
  height: 2.5rem;
  border-radius: 2px;
  border: none;
  width: 50%;
  outline: 0px solid transparent;
  color: black;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.search input::placeholder {
  /* padding-left: 2rem; */
  font-family: "Lato", sans-serif;
  color: black;
  text-align: center;
}

.search h1 {
  color: white;
}

.search p {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  text-align: justify;
  margin: 1rem 5%;
  width: 50%;
}

.search i {
  padding-right: 1rem;
  font-size: 1.5rem;
}

.search-item-card {
  width: 100%;
  height: auto;
  margin: 0 1rem;
}

.search-item-image-container div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.search-item-image-container div img {
  width: 10rem;
  height: auto;
}

.search-item-card-status {
  position: absolute;
  background-color: blue;
  color: white;
  left: 0;
  top: 1rem;
  padding: 0.5rem;
  font-family: "Lato", serif;
}

.search-item-card-info {
  font-family: "Lato", serif;
  color: #3a3a3a88;
}

.search-item-card-info div {
  height: 6rem;
}

.search-item-card-info h2 {
  margin: 1rem 0 1rem;
  color: #3a3a3a99;
  font-size: 1.4rem;
}

.search-logo-black {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.search-logo-black img {
  width: 30%;
  height: auto;
}

.search-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Lato", serif;
}

.search-details div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 50%;
}

.search-details h2 {
  color: #3a3a3a99;
  font-size: 1.4rem;
}

.search-details p,
.search-item-card-info p {
  color: #3a3a3a88;
  margin-left: 1rem;
}

.search-image-gallery {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.search-image-gallery img {
  margin: 0.5rem 0;
  height: auto;
  width: 22rem;
}

.search .text-filler {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}

.how-to-reserve {
  background-image: url(https://images.unsplash.com/photo-1449157291145-7efd050a4d0e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.how-to-reserve-info {
  width: 100%;
  margin: 5rem 0;
  font-family: "Lato", sans-serif;
}

.how-to-reserve-info h3 {
  margin-bottom: 2rem;
}

.how-to-reserve-info p {
  color: #3a3a3a99;
}

.how-to-reserve-info li {
  font-size: 14px;
  color: #3a3a3a99;
  padding: 0.4rem 0;
}

.how-to-reserve-info-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.how-to-reserve-info-img img {
  margin-bottom: 2rem;
  width: 10rem;
}

.how-to-reserve-info-img p {
  width: auto;
  margin-bottom: 5rem;
  font-family: "Lato", sans-serif;
  color: #3a3a3a99;
  text-align: justify;
}

.text-filler {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 0;
  width: 100%;
}

.text-filler img {
  width: 25%;
  height: 75%;
}

.text-filler-info,
.article-info {
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  text-align: justify;
  width: 100%;
  margin: 1rem auto;
  color: #3a3a3a99;
}

.article-heading-notActive {
  content: "\02795";
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #3a3a3a77;
  border-radius: 3px;
  height: 3rem;
  padding-left: 1rem;
  margin-top: 2rem;
}

.article-heading-notActive:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin: 0 0.3rem;
}

.article-heading-notActive:hover {
  background-color: #3a3a3a;
  transition: 300ms;
}

.article-heading-active {
  content: "\2212";
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #3a3a3a;
  border-radius: 3px 3px 0 0;
  height: 3rem;
  padding-left: 1rem;
  margin-top: 2rem;
}

.article-heading-active:after {
  content: "\2212";
  color: white;
  font-weight: bold;
  float: right;
  margin: 0 0.3rem;
}

.summary-hidden {
  /* display: none */
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s ease-out;
}

.summary-hidden .article-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height, 0.5s ease-out;
}

.summary-show {
  background-color: #3a3a3a22;
  border-radius: 0 0 2px 2px;
  transition: max-height, 0.5s ease-in;
  max-height: 700px;
}

.summary-show .article-container {
  transition: max-height, 0.5s ease-in;
  max-height: 700px;
}

.article-container {
  padding: 1rem;
  overflow: hidden;
}

.summary-show:last-of-type {
  margin-bottom: 2rem;
}

.text-filler-title {
  border-bottom: 1px solid #3a3a3a;
}

.text-filler-title,
.article-title {
  text-align: center;
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 100%;
  color: #3a3a3a;
  padding: 2rem;
  margin: auto;
}

.text-filler-title-howToReserve {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 50rem;
  color: #3a3a3a;
  padding: 2rem;
  margin: auto;
}

.footer {
  height: auto;
  width: 100%;
  background-color: #3a3a3a;
  color: #ffffff;
}

.footer-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  width: 80%;
}

.footer-info h1 {
  font-size: 1.5rem;
}

.footer-info div h5 {
  margin-bottom: 0.5rem;
}

.footer-links h1 {
  margin-left: 6rem;
}

.footer-links p {
  line-height: 0.5rem;
  margin-left: 6rem;
}

.footer-info div {
  padding: 2rem;
  margin: 0 auto;
  width: 33.3%;
}

.text-decoration-none {
  text-decoration: none;
  border: none;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: left;
}

.text-line-height-12rem {
  line-height: 1.5rem;
}

.font-green {
  color: rgb(0, 126, 0);
}

.font-blue {
  color: blue;
}

.font-grey {
  color: #3a3a3a88;
}

.font-size-25rem {
  font-size: 2.5rem;
}

.font-size-24rem {
  font-size: 2.4rem;
}

.font-size-15rem {
  font-size: 1.5rem;
}

.font-hind {
  font-family: "Hind Madurai", sans-serif;
}

.font-lato {
  font-family: "Lato", sans-serif;
}

.font-lora {
  font-family: "Lora", serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-raleway {
  font-family: "Raleway", sans-serif;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-300 {
  font-weight: 300;
}

.font-justify {
  text-align: justify;
}

.font-left {
  text-align: left;
}

.font-spacing {
  word-spacing: 2px;
}

.margin-top-zero {
  margin-top: 0;
}

.margin-bottom-zero {
  margin-bottom: 0;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-05rem {
  margin: 0 0.4rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-zero {
  padding: 0;
}

.border-none {
  border: none;
}

.button-show-image {
  background-color: #3a3a3a;
  border-radius: 2px;
  border: none;
  height: 2.5rem;
  color: #fff;
}

.button-active {
  border-bottom: 2px solid #3a3a3a;
  color: #3a3a3a;
}

.button-disabled {
  color: white;
  background-color: #3a3a3a11;
}

.back-to-top-button {
  position: fixed;
  right: 10px;
  top: 50%;
  background-color: white;
  border-radius: 50%;
  border: 1px solid transparent;
  padding: 1px 2px;
  z-index: 1;
  margin: 0;
}

.back-to-top-button i {
  font-size: 1.5rem;
  color: #3a3a3a;
}

.success {
  color: green;
}

.failed {
  color: red;
}

.page-not-found {
  width: 100%;
  height: 50vh;
  color: #3a3a3a99;
  border-bottom: 1px solid #3a3a3a99;
}

.page-not-found h3 {
  color: #3a3a3a;
}

.sitemap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 5rem 0;
}

.sitemap-first-div {
  /* display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly; */
  width: 15rem;
  height: auto;
  /* border: 1px solid blue; */
}

.sitemap a {
  text-decoration: none;
  color: #3a3a3a99;
}

.sitemap a:hover {
  text-decoration: underline;
  color: #3a3a3a;
  width: 50%;
}

/* .sitemap-allProperties {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: auto;
    border: 1px solid black;
} */

@media screen and (max-width: 1525px) {
  .card {
    margin: 0;
  }

  .gallery {
    width: 100%;
  }

  .sitemap-first-div {
    width: 20rem;
  }
}

@media screen and (max-width: 1440px) {
  .card-container {
    margin: auto 0;
    width: 100%;
  }

  .card {
    margin: 0;
  }

  .single-item-banner-container {
    width: 100%;
  }

  .footer-links p,
  .footer-links h1 {
    margin-left: 0;
  }

  .sitemap-first-div {
    width: 15rem;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }

  .contact-and-input-container {
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }

  .contact {
    margin-bottom: 4rem;
  }

  .input {
    width: 80%;
  }

  .text-filler-title,
  .text-filler-title-howToReserve {
    width: 100%;
  }

  .text-filler-info {
    width: 80%;
  }

  .footer-info {
    width: 100%;
  }

  .footer-info div {
    width: 50%;
  }

  .footer-links p,
  .footer-links h3 {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }

  .carousel-info-details {
    height: 20rem;
  }

  .carousel-info img {
    width: 7rem;
    max-height: 5rem;
  }

  .for-as-low-as,
  .description {
    font-size: 0.8rem;
  }

  .monthly {
    font-size: 1.2rem;
  }

  .properties-div-buttons-container {
    margin: 0 1rem;
  }

  .contact-and-input-container {
    flex-direction: column;
    align-items: center;
  }

  .contact {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 86%;
    height: 100%;
  }

  .footer-info {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .footer-links {
    margin: 0;
  }

  .footer-links p,
  .footer-links h3 {
    margin-left: 0;
    margin-top: 0;
  }

  .footer-info div {
    width: 40%;
    margin: 0;
  }

  .single-item-banner-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .single-item-banner-container div {
    width: 100%;
    min-height: auto;
    border-radius: 0;
  }

  .single-item-banner-container img {
    height: 10rem;
  }

  .display-hidden {
    display: none;
  }

  .display-visible {
    display: flex;
  }

  .properties-banner-content {
    width: 83%;
  }

  .page-banner-content h1,
  .page-banner-content p {
    text-align: left;
  }

  .properties-bestSeller,
  .properties-preSelling,
  .properties-rfo,
  .properties-premier {
    width: 45%;
  }

  .properties-allProperties {
    width: 100%;
  }

  .properties-button-container button {
    width: 100%;
  }

  .text-filler-title-howToReserve {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 95%;
  }

  .navbar-logo {
    width: 60%;
  }

  .navbar-links {
    width: 100%;
  }

  .navbar-links-Link {
    padding: 0 0.5rem;
  }

  .text-filler {
    width: 100%;
  }

  .text-filler h1 {
    width: 100%;
    color: #3a3a3a;
    text-align: center;
  }

  .text-filler-title,
  .text-filler-title-howToReserve {
    padding: 2rem 3rem;
  }

  .text-filler-info {
    width: 94%;
  }

  .input {
    width: 90%;
    padding-left: 0;
  }

  .input div {
    padding-left: 0;
  }

  .input-container input {
    padding-left: 2rem;
    width: 95%;
  }

  .input-container textarea {
    padding-left: 2rem;
    padding-right: 1rem;
    width: 90%;
  }

  .gallery img {
    width: 45rem;
    height: 23rem;
  }

  .search input {
    width: 85%;
  }

  .search h1 {
    width: 85%;
    text-align: left;
  }

  .search p {
    width: 85%;
  }

  .search-logo-black {
    width: 100%;
  }

  .search-logo-black img {
    width: 50%;
  }

  .search-image-gallery img {
    width: 100%;
  }

  .single-item-banner {
    height: auto;
  }

  .single-item-banner-container {
    flex-direction: column;
    min-height: auto;
  }

  .properties-div-buttons-container button {
    font-size: 1rem;
  }

  .properties-div a {
    font-size: 1.5rem;
  }

  .contact {
    width: 100%;
    margin-top: 0;
  }

  .contact-title {
    text-align: left;
  }

  .input-container input,
  .input-container textarea {
    width: 100%;
  }

  .properties-banner-content {
    width: 85%;
  }

  .properties-button-container {
    justify-content: center;
  }

  .properties-allProperties,
  .properties-bestSeller,
  .properties-preSelling,
  .properties-rfo,
  .properties-premier {
    width: 85%;
    height: 9rem;
  }

  .properties-allProperties button,
  .properties-bestSeller button,
  .properties-preSelling button,
  .properties-rfo button,
  .properties-premier button {
    width: 100%;
    height: 9rem;
  }

  .sitemap-first-div {
    width: 14rem;
  }
}

@media screen and (max-width: 576px) {
  .carousel-info-details {
    width: 25.5rem;
  }

  .contact {
    width: 100%;
  }

  .contact-title {
    width: 90%;
  }

  .text-filler {
    width: 100%;
  }

  .text-filler-title {
    font-size: 1.5rem;
    padding: 2rem 0;
  }

  .text-filler-info {
    font-size: 1rem;
    margin: 1rem 0;
  }

  .text-filler img {
    width: 25%;
    height: 75%;
  }

  .card {
    width: 100%;
    /* height: 100%; */
  }

  .card-container {
    justify-content: space-between;
  }

  .card-container a {
    width: 48%;
  }

  .card-image-container {
    height: 23rem;
  }

  .card-image-container div img {
    width: 50%;
    height: auto;
  }

  .card-info p:nth-child(3) {
    width: 100%;
    height: 6.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }

  .article-heading-active,
  .article-heading-notActive,
  .article-info {
    font-size: 1rem;
  }

  .button-view-more-properties {
    width: 100%;
  }

  .button-view-more-properties button {
    width: 90vw;
  }

  .sitemap-first-div {
    width: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .card-image-container {
    height: 17rem;
  }

  .font-size-15rem {
    font-size: 1.2rem;
  }

  .footer-info div {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .container {
    padding: 0;
    width: 95%;
  }

  .navbar-logo {
    width: 60%;
  }

  .search input {
    width: 85%;
  }

  .search h1 {
    text-align: center;
  }

  .search-details {
    flex-direction: column;
  }

  .contact {
    width: 100%;
  }

  .contact-title {
    text-align: left;
  }

  .contact-info {
    width: 100%;
  }

  .contact-info i {
    width: 1.2rem;
  }

  .contact-info p {
    width: 11rem;
  }

  .input-container input::placeholder,
  .input-container textarea::placeholder {
    font-size: 0.8rem;
  }

  .input-container input,
  .input-container textarea {
    width: 100%;
  }

  .navbar-links-Link li {
    font-size: 0.9rem;
    border: 1px solid white;
  }

  .font-size-24rem {
    font-size: 1.5rem;
  }

  .carousel-button-container {
    left: 30%;
    width: auto;
  }

  .carousel-button-container i {
    padding: 0 10px;
  }

  .font-size-15rem,
  .card-info h2 {
    font-size: 1.2rem;
  }

  .card {
    width: 100%;
    height: 35rem;
  }

  .card-image-container,
  .card-image-container div {
    height: 15rem;
  }

  .footer-info div {
    width: 100%;
  }

  .card-info p:nth-child(3) {
    height: 5.8rem;
    padding-bottom: 1rem;
    font-size: 0.9rem;
  }

  .card-info p:nth-child(4) {
    height: auto;
    padding-bottom: 1rem;
    font-size: 0.9rem;
  }

  .text-filler {
    margin: 2rem;
  }

  .text-filler img {
    width: 25%;
    height: 75%;
  }

  .text-filler-title,
  .text-filler-title-howToReserve {
    font-size: 1.7rem;
    padding: 2rem 0;
    margin-left: auto;
    width: 100%;
  }

  .article-heading-active,
  .article-heading-notActive,
  .article-info {
    font-size: 0.8rem;
  }

  .how-to-reserve-info {
    margin: 5rem 1rem;
    padding: 0 2rem;
  }

  .how-to-reserve-info-img p {
    margin: 0 2rem 2rem 2rem;
  }

  .gallery img {
    width: 100%;
  }

  .properties-banner-content p {
    text-align: justify;
  }

  .properties-bestSeller,
  .properties-preSelling,
  .properties-rfo,
  .properties-premier {
    width: 41%;
    margin: 1rem;
  }

  .properties-allProperties {
    width: 100%;
  }

  .button-view-more-properties button {
    font-size: 1rem;
  }

  .properties-div-buttons-container {
    margin: 0;
  }

  .properties-div-buttons-container button {
    font-size: 0.5rem;
  }

  .button-view-more-properties button {
    width: 23rem;
  }

  .properties-button-container button {
    width: 100%;
    margin: 1rem 0;
    font-size: 1rem;
    width: 10rem;
  }

  .properties-button-container {
    justify-content: space-evenly;
  }

  .carousel-info-details {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .carousel-info-details p {
    padding: 0.2rem 0.5rem;
    width: 80%;
    font-weight: 400;
  }

  .carousel-info img {
    width: 10rem;
    max-height: 9rem;
  }

  .single-item-banner-container div {
    min-height: auto;
    margin-bottom: 4rem;
  }

  .single-item-banner-container h1,
  .single-item-banner-container h2 {
    text-align: left;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }

  .single-item-banner-container p {
    text-align: left;
  }

  .single-item-banner-container li {
    margin-left: 1rem;
  }

  .footer-info h3 {
    font-size: 1.2rem;
  }

  .footer-info p {
    font-size: 0.9rem;
  }

  .slider-control-centerright button {
    opacity: 0;
  }
}

@media screen and (max-width: 375px) {
  .carousel-info-details {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .carousel-info-details p {
    padding: 0.5rem 0;
  }

  .font-size-15rem {
    font-size: 1rem;
  }

  .card-info div {
    height: 2rem;
  }

  .text-filler h1,
  .text-filler-title,
  .text-filler-title-howToReserve {
    font-size: 1.2rem;
  }
}
